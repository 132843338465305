import React, { useState } from "react";
import {
  Group,
  Button,
  TextInput,
  useMantineTheme,
  Textarea,
  FileInput,
  Input,
  Checkbox,
  Paper,
  Container,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { useDispatch } from "react-redux";

const CheckboxTwitter = ({ indeterminate, className }) => (
  <IconBrandTwitter className={className} size={20} />
);

const CheckboxFacebook = ({ indeterminate, className }) => (
  <IconBrandFacebook className={className} size={20} />
);

const CheckboxLinkedin = ({ indeterminate, className }) => (
  <IconBrandLinkedin className={className} size={20} />
);

const CheckboxInsta = ({ indeterminate, className }) => (
  <IconBrandInstagram className={className} size={20} />
);

function BusinessInfo({ onValidate, userData, prevStep, ...props }) {
  const [user, setUser] = useState(userData);
  const [TwitterSelected, setTwitterSelected] = useState(
    !!userData.socialData?.twitter
  );
  const [FacebookSelected, setFacebookSelected] = useState(
    !!userData.socialData?.facebook
  );
  const [LinkedinSelected, setLinkedinSelected] = useState(
    !!userData.socialData?.linkedin
  );
  const [InstaSelected, setInstaSelected] = useState(
    !!userData.socialData?.instagram
  );

  const [phone, setPhone] = useState(userData.phone || "");
  const [address, setAddress] = useState(userData.address || "");
  const [bio, setBio] = useState(userData.bio || "");
  const [company, setCompany] = useState(userData.company || "");
  const [job, setJob] = useState(userData.job || "");

  const [socialData, setSocialData] = useState(userData.socialData || {});

  function socialInput(e) {
    setSocialData({
      ...socialData,
      [e.target.name]: e.target.value,
    });
  }

  const handleValidate = () => {
    onValidate({ job, company, phone, address, bio, socialData, step_2: true });
  };

  const theme = useMantineTheme();

  return (
    <Container size={1080} my={40}>
      <Paper radius="md" p="xl" withBorder {...props}>
        <FileInput
          placeholder="Ajouter votre photo"
          className="upload_file"
          grow
        />

        <Group grow>
          <TextInput
            onChange={(e) => setJob(e.target.value)}
            value={job}
            label="Fonction"
            placeholder="Fonction"
            description=""
            inputWrapperOrder={["label", "error", "input", "description"]}
            style={{ marginBottom: "10px" }}
            withAsterisk
          />

          <TextInput
            onChange={(e) => setCompany(e.target.value)}
            value={company}
            label="Société"
            placeholder="Société"
            description=""
            inputWrapperOrder={["label", "input", "description", "error"]}
            style={{ marginBottom: "10px" }}
            withAsterisk
          />
        </Group>
        <TextInput
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          label="Numéro de téléphone"
          placeholder="+212 "
          description=""
          inputWrapperOrder={["label", "input", "description", "error"]}
          style={{ marginBottom: "10px" }}
          withAsterisk
        />

        <TextInput
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          label="Adresse"
          placeholder="Adresse"
          description=""
          inputWrapperOrder={["label", "input", "description", "error"]}
          style={{ marginBottom: "10px" }}
          withAsterisk
        />

        <Textarea
          onChange={(e) => setBio(e.target.value)}
          value={bio}
          label="Biographie"
          placeholder="Biographie"
          description=""
          inputWrapperOrder={["label", "input", "description", "error"]}
          style={{ marginBottom: "10px" }}
          withAsterisk
        />

        <Input.Wrapper
          label="Remplissez vos comptes de réseaux sociaux"
          required
          mx="auto"
        />
        <Group>
          <Group>
            <Checkbox
              icon={CheckboxTwitter}
              indeterminate
              size="lg"
              checked={TwitterSelected}
              onChange={() => setTwitterSelected(!TwitterSelected)}
            />
            {TwitterSelected ? (
              <Input
                name="twitter"
                icon={<IconBrandTwitter size="1rem" />}
                placeholder="Your twitter ID"
                onChange={(e) => socialInput(e)}
                value={socialData?.twitter || ""}
              />
            ) : null}
          </Group>

          <Group>
            <Checkbox
              icon={CheckboxFacebook}
              indeterminate
              size="lg"
              checked={FacebookSelected}
              onChange={() => setFacebookSelected(!FacebookSelected)}
            />
            {FacebookSelected ? (
              <Input
                name="facebook"
                icon={<IconBrandFacebook size="1rem" />}
                placeholder="Your Facebook ID"
                onChange={(e) => socialInput(e)}
                value={socialData?.facebook || ""}
              />
            ) : null}
          </Group>

          <Group>
            <Checkbox
              icon={CheckboxLinkedin}
              indeterminate
              size="lg"
              checked={LinkedinSelected}
              onChange={() => setLinkedinSelected(!LinkedinSelected)}
            />
            {LinkedinSelected ? (
              <Input
                name="linkedin"
                icon={<IconBrandLinkedin size="1rem" />}
                placeholder="Your Linkedin ID"
                onChange={(e) => socialInput(e)}
                value={socialData?.linkedin || ""}
              />
            ) : null}
          </Group>

          <Group>
            <Checkbox
              icon={CheckboxInsta}
              indeterminate
              size="lg"
              checked={InstaSelected}
              onChange={() => setInstaSelected(!InstaSelected)}
            />
            {InstaSelected ? (
              <Input
                name="instagram"
                icon={<IconBrandInstagram size="1rem" />}
                placeholder="Your Instagram ID"
                onChange={(e) => socialInput(e)}
                value={socialData?.instagram || ""}
              />
            ) : null}
          </Group>
        </Group>
        <Group className="button_modal">
          <Button variant="default" onClick={prevStep}>
            Back
          </Button>
          <Button
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
            size="xs"
            onClick={handleValidate}
          >
            Next step
          </Button>
        </Group>
      </Paper>
    </Container>
  );
}

export default BusinessInfo;
