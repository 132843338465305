import React, { useState } from "react";
import {
  Card,
  Image,
  Text,
  Button,
  Group,
  Container,
  createStyles,
  Title,
  Flex,
} from "@mantine/core";
import {
  IconShare,
  IconEdit,
  IconMail,
  IconPhone,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import "../../../assets/css/Profile.css";
import myImage from "../../../assets/img/profile_logo.png";
import CardInfo from "./CardInfo";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Update } from "../../../features/businessCard/businessSlice";

const useStyles = createStyles((theme) => ({
  image: {
    borderRadius: "50%",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
  title: {
    whiteSpace: "nowrap",
  },
  wrapper: {
    justifyContent: "space-between",
    alignItems: "center",
    background: "#b5a46a",
    padding: "1rem ",
    flexDirection: "column",
    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0",
    },
  },
  flex: {
    alignItems: "center",
    marginBottom: "1rem",
  },
  button: {
    transition: "background 0.5s",
    ":hover": {
      background: "#3b3a3a",
    },
  },
  contactIcon: {
    background: "#b5a46a",
    color: "#fff",
    padding: "5px",
    marginRight: "1rem",
    borderRadius: "50%",
    transition: "background 0.5s",
    ":hover": {
      background: "#b89000",
    },
  },
  socialIcon: {
    background: "#b5a46a",
    color: "#fff",
    padding: "5px",
    marginRight: "1rem",
    transition: "background 0.5s",
    ":hover": {
      background: "#b89000",
    },
  },
  icon: {
    color: "#b5a46a",
    background: "#fff",
    padding: "7px",
    borderRadius: "50%",
    transition: "boxShadow 0.5s",
    ":hover": {
      boxShadow: "0px 2px 10px #00000038",
    },
  },
}));

const socialMediaIcons = {
  linkedin: IconBrandLinkedin,
  twitter: IconBrandTwitter,
  facebook: IconBrandFacebook,
  instagram: IconBrandInstagram,
};

export default function MyCard() {
  const { classes } = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { bc, tempBCInfo, tempBCSocial } = useSelector((state) => state.bc);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleEditClick = () => {
    setIsEdit(!isEdit);
  };

  const handleEditClickCheck = async () => {
    setIsEdit(!isEdit);
    dispatch(
      Update({
        data: { ...tempBCInfo, social_media_accounts: tempBCSocial },
        token,
      })
    );
  };

  return isEdit ? (
    <CardInfo handleEditClick={handleEditClickCheck} />
  ) : (
    <Container m="0" pl="0" maw="22rem">
      <Card shadow="sm" pt="0" pr="0" pl="0" radius="md" withBorder>
        <Flex
          style={{
            justifyContent: "space-between",
            background: "#b5a46a",
            padding: "2rem 1rem",
          }}
        >
          <Link>
            <IconShare className={classes.icon} />
          </Link>

          <Flex className={classes.wrapper}>
            <Image
              className={classes.image}
              src={myImage}
              height={"90px"}
              width={"95px"}
              alt="Profile"
            />
            <div>
              <Title className={classes.title} c="#fff" fz="1.2rem" ta="center">
                {bc ? bc.business_name : `${user.nom} ${user.prénom}`}
              </Title>
              <Text ta="center" c="#fff">
                {bc ? bc.profession : "Profession"}
              </Text>
            </div>
          </Flex>
          <Link>
            <IconEdit className={classes.icon} />
          </Link>
        </Flex>

        <div className="information-wrapper">
          <Button
            className={classes.button}
            color="white"
            bg="#535353"
            fullWidth
            mt="md"
            radius="md"
          >
            Ajouter aux contacts
          </Button>
          <Group position="apart" mt="md" mb="xs">
            <Text weight={500}>À-propos</Text>
          </Group>
          <Text size="sm" color="dimmed">
            {bc ? bc.business_details : "Please create a business card"}
          </Text>

          <Group display="block" position="apart" mt="md" mb="xs">
            <Text mb="1rem" weight={500}>
              Contact
            </Text>

            <Flex className={classes.flex}>
              <IconMail className={classes.contactIcon} />
              <Text size="sm" color="dimmed">
                {bc ? bc.email_adress : user.email}
              </Text>
            </Flex>

            <Flex className={classes.flex}>
              <IconPhone className={classes.contactIcon} />
              <Text size="sm" color="dimmed">
                {bc ? bc.phone_number : "Please create a business card"}
              </Text>
            </Flex>
          </Group>

          <Group display="block" position="apart" mt="md" mb="xs">
            <Text mb="1rem" weight={500}>
              Réseaux sociaux
            </Text>

            <Flex className="social-wrapper">
              {Object.entries(bc?.social_media_accounts || {}).map(
                ([platform, username]) => {
                  const Icon = socialMediaIcons[platform.toLowerCase()];
                  if (!Icon) return null;
                  return (
                    <a
                      key={platform}
                      href={`https://www.${platform}.com/${username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon className={classes.socialIcon} />
                    </a>
                  );
                }
              )}
            </Flex>
          </Group>
        </div>
      </Card>
    </Container>
  );
}
