import React from "react";
import "../../assets/css/Custom.css";
import {
  FaUpload,
  FaPlus,
  FaPhoneAlt,
  FaRegEnvelope,
  FaRegEdit,
} from "react-icons/fa";
import myImage from "../../assets/img/profile_logo.png";
import SocialMedia from "../../scan_side/SocialMedia";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Center, Loader } from "@mantine/core";

const socialMediaIcons = {
  facebook: IconBrandFacebook,
  twitter: IconBrandTwitter,
  linkedin: IconBrandLinkedin,
  instagram: IconBrandInstagram,
};

export default function BusinessCard() {
  const { bc, isLoading } = useSelector((state) => state.bc);

  const handleDownload = () => {
    const vcfData = `
      BEGIN:VCARD
      VERSION:3.0
      FN:${bc?.business_name || ""}
      TEL:${bc?.phone_number || ""}
      EMAIL:${bc?.email_adress || ""}
      END:VCARD
    `;

    const blob = new Blob([vcfData], { type: "text/vcard;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${bc?.business_name || "Contact"}Contacts.vcf`;

    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(event);

    window.URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <Center h={400}>
        <Loader color="blue" />
      </Center>
    );
  }

  const socialMediaAccounts = bc?.social_media_accounts || {};

  return (
    <div>
      <div className="qrc_page_wrapper thinScrollBar">
        <div className="qrc_page_cover"></div>
        <div className="qrc_page_inner qrc_page_inner_2">
          <div className="section qrc_profile qrc_profile_2">
            <div className="qrc_profile_inner">
              <img src={myImage} className="qrc_profilepic" alt="Profile" />
              <h2>{bc?.business_name || "Business Name"}</h2>
              <p>{bc?.profession || "Profession"}</p>
              <p>
                <strong>{bc?.societe || "Company"}</strong>
              </p>
              <div className="qrc_profile_shortcut">
                <ul>
                  {bc?.phone_number && (
                    <li className="qr_cc_card">
                      <a rel="nofollow" href={`tel:${bc.phone_number}`}>
                        <FaPhoneAlt color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                  {bc?.email_adress && (
                    <li className="qr_cc_card">
                      <a rel="nofollow" href={`mailto:${bc.email_adress}`}>
                        <FaRegEnvelope color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                  {bc?.phone_number && (
                    <li className="qr_cc_card">
                      <a rel="nofollow" href={`sms:${bc.phone_number}`}>
                        <FaRegEdit color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="section qrc_heading_text qr_cc_card">
            <h2 className="section-title">About Me</h2>
            <p>{bc?.business_details || "Business details"}</p>
          </div>

          <div className="section qrc_contact qr_cc_card">
            <div className="qrc_contact_header">
              <div className="qrc_profile_shortcut" style={{ margin: "0px" }}>
                <ul>
                  {bc?.phone_number && (
                    <li
                      className="qr_cc_card"
                      style={{
                        backgroundColor: "#07031A",
                        width: "40px",
                        height: "40px",
                        marginTop: "0px",
                      }}
                    >
                      <a rel="nofollow" href={`sms:${bc.phone_number}`}>
                        <FaPhoneAlt color="white" size={19} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="qrc_contact_hdr_text">Contact Us</div>
            </div>
            {bc?.phone_number && (
              <div className="qrc_contact_info">
                <div className="qrc_contact_info_title">Call Us</div>
                <div className="qrc_contact_number">
                  <a href={`tel:${bc.phone_number}`}>{bc.phone_number}</a>
                </div>
              </div>
            )}
            {bc?.email_adress && (
              <div className="qrc_email_info">
                <div className="qrc_email_info_title">Email</div>
                <div className="qrc_email_id">
                  <a href={`mailto:${bc.email_adress}`}>{bc.email_adress}</a>
                </div>
              </div>
            )}
            {bc?.business_adress && (
              <div className="qrc_address_info">
                <div className="qrc_address_info_title">Address</div>
                <div className="qrc_address_text">{bc.business_adress}</div>
              </div>
            )}
          </div>

          <div className="section qrc_social_links qrc_heading_text">
            <h2 className="section-title">Social Media</h2>
            <ul
              className="qrc_social_links_list"
              style={{ display: "flex", gap: "10px" }}
            >
              {Object.entries(socialMediaAccounts).map(
                ([platform, username]) => {
                  const Icon = socialMediaIcons[platform.toLowerCase()];

                  if (!Icon) {
                    console.error(`No icon found for ${platform}`);
                    return null;
                  }
                  return (
                    <SocialMedia
                      key={platform}
                      Icon={Icon}
                      data={username}
                      name={platform}
                    />
                  );
                }
              )}
            </ul>
          </div>
        </div>
        <div className="extra_button_wrapper">
          <a className="qrc_addtocontact" onClick={handleDownload}>
            <div className="qrc_addtocontact_text">Add to Contact</div>
            <div className="qrc_addtocontact_circle">
              <FaPlus color="#07031A" size={15} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
