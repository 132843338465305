import React, { useEffect, useState } from "react";
import "../assets/css/Custom.css";
import { useLocation } from "react-router-dom";
import {
  FaUpload,
  FaPlus,
  FaPhoneAlt,
  FaRegEnvelope,
  FaRegEdit,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import { GetViewScan } from "../features/QrScann/View/viewSlice";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import myImage from "../assets/img/profile_logo.png";
import SocialMedia from "./SocialMedia";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";

export default function BcScreenView() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading, businessCard } = useSelector((state) => state.qr_scanner);
  const [bcData, setBcData] = useState(businessCard);

  useEffect(() => {
    const getparam = location;
    const sliceParam = getparam.pathname.split("/");
    const view_id = sliceParam[2];
    dispatch(GetViewScan(view_id));
  }, [dispatch, location]);

  const handleDownload = () => {
    const vcfData = `
      BEGIN:VCARD
      VERSION:3.0
      FN:${businessCard?.business_card.business_name}
      TEL:${businessCard?.business_card.phone_number}
      EMAIL:${businessCard?.business_card.email_adress}
      END:VCARD
    `;

    const blob = new Blob([vcfData], { type: "text/vcard;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${businessCard?.business_card.business_name}Contacts.vcf`;

    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(event);
    window.URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="qrc_page_wrapper custom_spinner">
        <BeatLoader color="#36d7b7" />
      </div>
    );
  }

  const socialMediaAccounts =
    businessCard?.business_card?.social_media_accounts || {};

  return (
    <div>
      <div className="qrc_page_wrapper thinScrollBar">
        <div className="qrc_page_cover"></div>
        <div className="qrc_page_inner qrc_page_inner_2">
          <div className="section qrc_profile qrc_profile_2">
            <div className="qrc_profile_inner">
              <img src={myImage} className="qrc_profilepic" alt="Profile" />
              <h2>{businessCard?.business_card.business_name}</h2>
              <p></p>
              <div className="qrc_profile_shortcut">
                <ul>
                  {businessCard?.business_card?.phone_number && (
                    <li className="qr_cc_card">
                      <a
                        rel="nofollow"
                        href={`tel:${businessCard.business_card.phone_number}`}
                      >
                        <FaPhoneAlt color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                  {businessCard?.business_card?.email_adress && (
                    <li className="qr_cc_card">
                      <a
                        rel="nofollow"
                        href={`mailto:${businessCard.business_card.email_adress}`}
                      >
                        <FaRegEnvelope color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                  {businessCard?.business_card?.phone_number && (
                    <li className="qr_cc_card">
                      <a
                        rel="nofollow"
                        href={`sms:${businessCard.business_card.phone_number}`}
                      >
                        <FaRegEdit color="#07031A" size={19} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="section qrc_heading_text qr_cc_card">
            <h2>About Me</h2>
            <p>{businessCard?.business_card.business_details}</p>
          </div>
          <div className="section qrc_contact qr_cc_card">
            <div className="qrc_contact_header">
              <div className="qrc_profile_shortcut" style={{ margin: "0px" }}>
                <ul>
                  {businessCard?.business_card?.phone_number && (
                    <li
                      className="qr_cc_card"
                      style={{
                        backgroundColor: "#07031A",
                        width: "40px",
                        height: "40px",
                        marginTop: "0px",
                      }}
                    >
                      <a
                        rel="nofollow"
                        href={`sms:${businessCard.business_card.phone_number}`}
                      >
                        <FaPhoneAlt color="white" size={19} />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="qrc_contact_hdr_text">Contact Us</div>
            </div>
            {businessCard?.business_card?.phone_number && (
              <div className="qrc_contact_info">
                <div className="qrc_contact_info_title">Call Us</div>
                <div className="qrc_contact_number">
                  <a href={`tel:${businessCard.business_card.phone_number}`}>
                    {businessCard.business_card.phone_number}
                  </a>
                </div>
              </div>
            )}
            {businessCard?.business_card?.email_adress && (
              <div className="qrc_email_info">
                <div className="qrc_email_info_title">Email</div>
                <div className="qrc_email_id">
                  <a href={`mailto:${businessCard.business_card.email_adress}`}>
                    {businessCard.business_card.email_adress}
                  </a>
                </div>
              </div>
            )}
            {businessCard?.business_card?.business_adress && (
              <div className="qrc_address_info">
                <div className="qrc_address_info_title">Address</div>
                <div className="qrc_address_text">
                  {businessCard.business_card.business_adress}
                </div>
              </div>
            )}
          </div>
          <div className="section qrc_social_links qrc_heading_text">
            <h2>Social Media</h2>
            <ul
              className="qrc_social_links_list"
              style={{ display: "flex", gap: "10px" }}
            >
              {socialMediaAccounts.facebook && (
                <SocialMedia
                  Icon={IconBrandFacebook}
                  data={socialMediaAccounts.facebook}
                  name={"Facebook"}
                />
              )}
              {socialMediaAccounts.twitter && (
                <SocialMedia
                  Icon={IconBrandTwitter}
                  data={socialMediaAccounts.twitter}
                  name={"Twitter"}
                />
              )}
              {socialMediaAccounts.linkedin && (
                <SocialMedia
                  Icon={IconBrandLinkedin}
                  data={socialMediaAccounts.linkedin}
                  name={"Linkedin"}
                />
              )}
              {socialMediaAccounts.instagram && (
                <SocialMedia
                  Icon={IconBrandInstagram}
                  data={socialMediaAccounts.instagram}
                  name={"Instagram"}
                />
              )}
            </ul>
          </div>
        </div>
        <div className="extra_button_wrapper">
          <a className="qrc_addtocontact" onClick={handleDownload}>
            <div className="qrc_addtocontact_text">Add to Contact</div>
            <div className="qrc_addtocontact_circle">
              <FaPlus color="#07031A" size={15} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
