import "../assets/css/Login.css";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Login as signIn, reset } from "../features/auth/authSlice";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const notebook_id = queryParams.get("id");

  const { isLoading, message, isAuth, isSuccess } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      toast.success(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/profile"); // Redirect to profile on successful login
      dispatch(reset());
    } else if (isSuccess === false) {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [isSuccess, message, navigate, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signIn({ email, password, notebook_id }));
  };

  return (
    <Container
      display={"flex"}
      className="page-flex page-wrapper"
      size={420}
      my={40}
    >
      <ToastContainer />
      <form onSubmit={submitHandler}>
        <Paper
          bg={"transparent"}
          withBorder
          shadow="none"
          p={30}
          mt={0}
          radius="md"
        >
          <Title align="center">InnerMind</Title>
          <Title fz="15px" c="#828282" fw={500} mt="lg" ta="center" mb="lg">
            Connectez-vous pour pouvoir utiliser votre compte InnerMind
          </Title>
          <TextInput
            label="Email"
            placeholder="Saisir votre email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <PasswordInput
            label="Mot de passe"
            placeholder="Saisir votre mot de passe"
            mt="md"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Group position="apart" mt="lg">
            <Checkbox label="Se souvenir de moi" />
            <Link
              to="/password/reset"
              className="forgotPassword-link"
              component="button"
              size="sm"
            >
              Mot de passe oublié ?
            </Link>
          </Group>
          <Button type="submit" fullWidth mt="xl" bg="#a1974b">
            {isLoading ? "Chargement..." : "Se connecter"}
          </Button>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            Vous n'avez pas de compte ?{" "}
            <Link to="/register" className="register-link">
              Inscrivez-vous
            </Link>
          </Text>
        </Paper>
      </form>
    </Container>
  );
}
