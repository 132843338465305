import { Footer } from "./Footer";
import { PageHeader } from "./Header";
import { NormalHeader } from "./NormalHeader";
import { NormalFooter } from "./NormalFooter";
import "../../assets/css/App.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "./Loader";

function Layout({ children }) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const { pathname } = location;

  const renderLayout = () => {
    if (isLoading) {
      return <Loader isLoading={isLoading} />;
    }

    switch (pathname) {
      case "/login":
      case "/register":
      case "/password/reset":
        return (
          <>
            <NormalHeader />
            {children}
            <NormalFooter />
          </>
        );
      case "/profile":
        return <>{children}</>;
      default:
        return (
          <>
            <PageHeader />
            {children}
            <Footer />
          </>
        );
    }
  };

  return (
    <div
      className="App"
      style={{ height: "100vh", width: "100vw", overflowX: "hidden" }}
    >
      {renderLayout()}
    </div>
  );
}

export default Layout;
