import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/Home";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { PasswordReset } from "./pages/PasswordReset";
import { Contact } from "./pages/Contact";
import { HowItWorks } from "./pages/HowItWorks";
import { FaqPage } from "./pages/FaqPage";
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy";
import Profile from "./pages/Profile";
import CardInfo from "./components/layout/Profile/CardInfo";
import Stats from "./components/layout/Profile/Stats";
import Chart from "./components/layout/Profile/Chart";
import BcScreenView from "./scan_side/Bc_screen_view";
import StepperRegister from "./screens/Stepper";
import QRCodeScanner from "./scan_side/QRCodeScanner";
import PublicRoute from "./lib/PublicRoute";
import PrivateRoute from "./lib/PrivateRoute";
import { validateToken } from "./utils/tokenValidation";
import { logout } from "./features/auth/authSlice";
import "./assets/css/App.css";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (token) {
      const tokenIsValid = validateToken(token);
      if (!tokenIsValid) {
        dispatch(logout());
        navigate("/login");
      }
    }
  }, [dispatch, token, navigate]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/cardInfo" element={<CardInfo />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/chart" element={<Chart />} />
      </Route>
      <Route element={<PublicRoute restricted={false} />}>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/faqpage" element={<FaqPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Route>
      <Route element={<PublicRoute restricted={false} />}>
        <Route path="/register" element={<StepperRegister />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password/reset" element={<PasswordReset />} />
      </Route>
      <Route path="/view/:id" element={<BcScreenView />} />
      <Route path="/:id" element={<QRCodeScanner />} />
    </Routes>
  );
}

export default App;
