import axios from "axios";

const home = async () => {
  const response = await axios.get("http://api-innermind.lien.ma/home", {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
  return response.data;
};

const homePageData = { home };
export default homePageData;
