import axios from "axios";

const ScanQrView = async ({ view_id }) => {
  const response = await axios.get(
    `https://api-innermind.lien.ma/view/${view_id}`
  );
  return response.data;
};

const viewApi = { ScanQrView };
export default viewApi;
