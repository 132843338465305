import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Text, Title, Card, Group, Button } from "@mantine/core";
import BusinessConfirmation from "../../View";
import { Register as signUp } from "../../../features/auth/authSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserDataCard({ title, data }) {
  return (
    <Card shadow="sm" padding="md" radius="md" style={{ marginBottom: "20px" }}>
      <Title order={2}>{title}</Title>
      {Object.entries(data).map(([key, value]) => (
        <Text key={key} size="sm">
          <strong>{key}:</strong> {value}
        </Text>
      ))}
    </Card>
  );
}

function ConfirmationStep({ userData, prevStep }) {
  const navigate = useNavigate();
  const { message, isSuccess } = useSelector((state) => state.auth);

  const [messageSelector, setMessageSelector] = useState(message);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const notebook_id = queryParams.get("id");
  const dispatch = useDispatch();
  const {
    step_1,
    step_2,
    job,
    company,
    phone,
    address,
    bio,
    socialData,
    email,
    password,
    nom,
    prénom,
  } = userData;

  const submitHandler = async (e) => {
    try {
      if (email && password) {
        if (step_1 && step_2) {
          dispatch(
            signUp({
              step_1,
              step_2,
              job,
              company,
              phone,
              address,
              bio,
              socialData,
              email,
              password,
              nom,
              prénom,
              notebook_id, // notebook_id can be undefined or null
            })
          );
        } else if (step_1) {
          dispatch(
            signUp({
              email,
              password,
              nom,
              prénom,
              notebook_id, // notebook_id can be undefined or null
            })
          );
        } else {
          console.error("Step 1 condition is false");
        }
      } else {
        console.error("Email or password not provided");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (isSuccess === false) {
      toast.error(messageSelector, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [messageSelector]);

  useEffect(() => {
    if (isSuccess === true) {
      toast.success(messageSelector, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/login");
    }
  }, [isSuccess]);

  return (
    <div>
      <BusinessConfirmation userData={userData} />
      <ToastContainer />
      <UserDataCard
        title="Step 1: Name and Email"
        data={{
          Name: userData.nom,
          LastName: userData.prénom,
          Email: userData.email,
        }}
      />
      <UserDataCard
        title="Step 2: Job, Company, and Contact"
        data={{
          Job: userData.job,
          Company: userData.company,
          Phone: userData.phone,
          Address: userData.address,
          Bio: userData.bio,
        }}
      />
      <UserDataCard
        title="Step 3: Bio and Social Media"
        data={{
          Bio: userData.bio,
          Twitter: userData?.socialData?.twitter || "Not provided",
          Facebook: userData?.socialData?.facebook || "Not provided",
          LinkedIn: userData?.socialData?.linkedin || "Not provided",
          Instagram: userData?.socialData?.instagram || "Not provided",
        }}
      />
      <Group className="button_modal">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="gradient"
          gradient={{ from: "blue", to: "cyan", deg: 90 }}
          size="xs"
          onClick={() => submitHandler()}
        >
          Submit
        </Button>
      </Group>
    </div>
  );
}

export default ConfirmationStep;
