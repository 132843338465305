import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAPI from "./authApi";
import { validateToken } from "../../utils/tokenValidation";

// Initial state
const initialState = {
  isSuccess: false,
  isLoading: false,
  token: localStorage.getItem("token"),
  message: null,
  register: null,
  isAuth:
    localStorage.getItem("token") &&
    validateToken(localStorage.getItem("token"))
      ? true
      : false,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
};

// login
export const Login = createAsyncThunk(
  "auth/Login",
  async ({ email, password, notebook_id }, thunkAPI) => {
    try {
      const response = await authAPI.login({ email, password, notebook_id });
      return response;
    } catch (error) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Register
export const Register = createAsyncThunk(
  "auth/Register",
  async (userData, thunkAPI) => {
    try {
      return await authAPI.register(userData);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get profile
export const GetProfile = createAsyncThunk(
  "auth/GetProfile",
  async (token, thunkAPI) => {
    try {
      return await authAPI.getProfile(token);
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Send reset code
export const sendResetCode = createAsyncThunk(
  "auth/sendResetCode",
  async ({ email }, thunkAPI) => {
    try {
      return await authAPI.sendResetCode({ email });
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reset password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, resetCode, newPassword }, thunkAPI) => {
    try {
      return await authAPI.resetPassword({ email, resetCode, newPassword });
    } catch (error) {
      const message = error.toString() || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false;
      state.isLoading = false;
      state.message = null;
    },
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("isAuth");
      localStorage.removeItem("user");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Login.pending, (state) => {
        state.isLoading = true;
        state.message = null;
      })
      .addCase(Login.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload.token);
        localStorage.setItem("isAuth", true);
        state.token = action.payload.token;
        state.isSuccess = true;
        state.isLoading = false;
        state.isAuth = true;
        state.message = action.payload?.Authentication;
      })
      .addCase(Login.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.message = action.payload || "Accès refusé! Données invalide";
      })
      .addCase(Register.pending, (state) => {
        state.isLoading = true;
        state.message = null;
      })
      .addCase(Register.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.register = action.payload.savedUser;
        state.message = action.payload.message;
        localStorage.setItem("register", JSON.stringify(action.payload));
      })
      .addCase(Register.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.message = "Cet email est déjà utilisé";
      })
      .addCase(sendResetCode.pending, (state) => {
        state.isLoading = true;
        state.message = null;
      })
      .addCase(sendResetCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(sendResetCode.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.message = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(GetProfile.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(GetProfile.fulfilled, (state, action) => {
        localStorage.setItem("user", JSON.stringify(action.payload));
        state.user = action.payload;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(GetProfile.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.message = action.error?.message;
      });
  },
});

export const { reset, logout } = authSlice.actions;
export default authSlice.reducer;
