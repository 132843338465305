import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const QRCodeScanner = () => {
  const { id } = useParams();
  //   const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [accountStatus, setAccountStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [accountData, setAccountData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyQRCode = async () => {
      try {
        const response = await fetch(
          `https://api-innermind.lien.ma/config/${id}`
        );
        const data = await response.json();

        if (!data.account_status) {
          // Handle the case when the NoteBook is not found
          setIsLoading(false);
          return;
        }

        // Update state with the account data
        setAccountStatus(data.account_status);
        setAccountData(data.account);
        setStatus(data.status);
      } catch (error) {
        console.error("Error verifying QR code:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    // Start the process by verifying the QR code
    verifyQRCode();
  }, [id]);

  useEffect(() => {
    // Redirect based on the account status
    if (!isLoading) {
      if (accountStatus && status === 200) {
        // Redirect to a route for the registered notebook
        // history.push(`/view/${id}`);
        navigate(`/view/${id}`);
      } else {
        // Redirect to a route for registering the notebook
        // history.push(`/register/${id}`);
        navigate(`/register?id=${id}`);
      }
    }
  }, [isLoading, accountStatus, id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render other components if needed
  return (
    <div>
      {/* Your QR Code Scanner component or other content */}
      {accountData && (
        <div>
          Account Data:
          <pre>{JSON.stringify(accountData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
