import React, { useEffect, useState } from "react";
import "../../assets/css/Custom.css";
import FileSVG from "../../assets/svg/file.svg";

import {
  FaUpload,
  FaPlus,
  FaPhoneAlt,
  FaRegEnvelope,
  FaRegEdit,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import myImage from "../../assets/img/profile_logo.png";
import SocialMedia from "../../scan_side/SocialMedia";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";

export default function BusinessConfirmation({ userData }) {
  const [bcData, setBcData] = useState(userData);

  const handleDownload = () => {
    const vcfData = `
        BEGIN:VCARD
        VERSION:3.0
        FN:${`${bcData?.nom} ${bcData?.prénom}`}
        TEL:${bcData.phone}
        EMAIL:${bcData.email}
        END:VCARD
    `;

    const blob = new Blob([vcfData], { type: "text/vcard;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${bcData?.nom}Contacts.vcf`;

    // Create a custom event to trigger the click on mobile devices
    const event = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    a.dispatchEvent(event);

    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <div className="qrc_page_wrapper thinScrollBar">
        <div
          className="qrc_page_cover"
          // style="background-image: url('/images/digitalCard/dbcv2/bg_1.webp');"
        ></div>
        <div
          className="qrc_page_inner qrc_page_inner_2"
          // style="background:#608FFF"
        >
          <div className="section qrc_profile qrc_profile_2">
            <div className="qrc_profile_inner">
              <div
                className=""
                // style="background-image: url('/images/digitalCard/dbcv2/profile_16.webp');"
              >
                <img src={myImage} className="qrc_profilepic" />
              </div>
              <h2>
                {bcData.nom} {bcData.prénom}
              </h2>
              {/* <p>Title</p> */}
              <p>{/* <strong>Company</strong> */}</p>
              <div className="qrc_profile_shortcut">
                <ul>
                  <li className="qr_cc_card">
                    <a rel="nofollow" href={`tel:${bcData.phone}`}>
                      <FaPhoneAlt color="#07031A" size={19} />
                    </a>
                  </li>
                  <li className="qr_cc_card">
                    <a rel="nofollow" href={`mailto:${bcData.email}`}>
                      <FaRegEnvelope color="#07031A" size={19} />
                    </a>
                  </li>
                  <li className="qr_cc_card">
                    <a rel="nofollow" href={`sms:${bcData.phone}`}>
                      <FaRegEdit color="#07031A" size={19} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>{" "}
          <div className="section qrc_heading_text qr_cc_card">
            <h2>About Me</h2> <p>{bcData.bio}</p>
          </div>
          <div className="section qrc_contact qr_cc_card">
            <div className="qrc_contact_header">
              <div className="qrc_profile_shortcut" style={{ margin: "0px" }}>
                <ul>
                  <li
                    className="qr_cc_card"
                    style={{
                      backgroundColor: "#07031A",
                      width: "40px",
                      height: "40px",
                      marginTop: "0px",
                    }}
                  >
                    <a rel="nofollow" href={`sms:${bcData.phone}`}>
                      <FaPhoneAlt color="white" size={19} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="qrc_contact_hdr_text">Contact Us</div>
            </div>
            <div className="qrc_contact_info">
              <div className="qrc_contact_info_title">Call Us</div>
              <div className="qrc_contact_number">
                <a href={`tel:${bcData.phone}`}>{bcData.phone}</a>
              </div>
            </div>
            <div className="qrc_email_info">
              <div className="qrc_email_info_title">Email</div>
              <div className="qrc_email_id">
                <a href={`mailto:${bcData.email}`}>{bcData.email}</a>
              </div>
            </div>
            <div className="qrc_address_info">
              <div className="qrc_address_info_title">Address</div>
              <div className="qrc_address_text">{bcData.address}</div>
            </div>
          </div>{" "}
          <div className="section qrc_social_links">
            <ul className="qrc_social_links_list"></ul>
          </div>{" "}
          <div className="section qrc_social_links">
            <ul className="qrc_social_links_list">
              <li className="qr_cc_card">
                <div className="qrc_heading">
                  <h2>Web Links</h2> <p>Description</p>{" "}
                </div>
                <div className="socialmedia_container">
                  {bcData && bcData.socialData && bcData.socialData.facebook ? (
                    <SocialMedia
                      Icon={IconBrandFacebook}
                      data={bcData.socialData.facebook}
                      name={"Facebook"}
                    />
                  ) : (
                    ""
                  )}
                  {bcData && bcData.socialData && bcData.socialData.twitter ? (
                    <SocialMedia
                      Icon={IconBrandTwitter}
                      data={bcData.socialData.twitter}
                      name={"Twitter"}
                    />
                  ) : (
                    ""
                  )}
                  {bcData && bcData.socialData && bcData.socialData.linkedin ? (
                    <SocialMedia
                      Icon={IconBrandLinkedin}
                      data={bcData.socialData.linkedin}
                      name={"Linkedin"}
                    />
                  ) : (
                    ""
                  )}
                  {bcData &&
                  bcData.socialData &&
                  bcData.socialData.instagram ? (
                    <SocialMedia
                      Icon={IconBrandInstagram}
                      data={bcData.socialData.instagram}
                      name={"Instagram"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="extra_button_wrapper">
          <a className="qrc_addtocontact" onClick={handleDownload}>
            <div className="qrc_addtocontact_text">Add to Contact</div>
            <div className="qrc_addtocontact_circle">
              <FaPlus color="#07031A" size={15} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
