import React from "react";
import { Container } from "@mantine/core";
import BusinessCard from "../../businessCard";

const Dashboard = ({ data }) => {
  return (
    <Container>
      <BusinessCard userData={data} />
    </Container>
  );
};

export default Dashboard;
