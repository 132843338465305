import axios from "axios";

const login = async ({ email, password }) => {
  const response = await axios.post(
    `https://api-innermind.lien.ma/auth/login`,
    {
      email: email,
      password,
    }
  );
  return response.data;
};

const register = async ({
  step_1,
  step_2,
  job,
  company,
  phone,
  address,
  bio,
  socialData,
  email,
  password,
  nom,
  prénom,
  notebook_id,
}) => {
  const response = await axios.post(
    `https://api-innermind.lien.ma/auth/register${
      notebook_id ? `?id=${notebook_id}` : "/"
    }`,
    {
      step_1,
      step_2,
      job,
      company,
      phone,
      address,
      bio,
      socialData,
      email,
      password,
      first_name: nom,
      family_name: prénom,
    }
  );
  return response.data;
};

const getProfile = async (token) => {
  const response = await axios.get(
    "https://api-innermind.lien.ma/account/user",
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const sendResetCode = async ({ email }) => {
  const response = await axios.post(
    "https://api-innermind.lien.ma/auth/forgot-password",
    {
      email,
    }
  );
  return response.data;
};

const resetPassword = async ({ email, resetCode, newPassword }) => {
  const response = await axios.post(
    "https://api-innermind.lien.ma/auth/reset-password",
    {
      email,
      resetCode,
      newPassword,
    }
  );
  return response.data;
};

const authAPI = { login, register, getProfile, sendResetCode, resetPassword };
export default authAPI;
