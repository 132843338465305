import axios from "axios";

const create = async (data, token) => {
  const response = await axios.post(
    "https://api-innermind.lien.ma/bc_customization/create",
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const update = async (data, token) => {
  const response = await axios.put(
    "https://api-innermind.lien.ma/bc_customization/update",
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const getOne = async (token) => {
  const response = await axios.get(
    `https://api-innermind.lien.ma/bc_customization/businesscard`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const bcAPI = { create, update, getOne };
export default bcAPI;
