import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Group,
} from "@mantine/core";
import { IconArrowUpRight, IconArrowDownRight } from "@tabler/icons-react";

const data = [
  {
    label: "Click to call",
    stats: "10",
    progress: 50,
    color: "teal",
    icon: "up",
  },
  {
    label: "Scans",
    stats: "20",
    progress: 75,
    color: "blue",
    icon: "up",
  },
  {
    label: "Partages",
    stats: "20",
    progress: 75,
    color: "red",
    icon: "down",
  },
];

const icons = {
  up: IconArrowUpRight,
  down: IconArrowDownRight,
};

export default function Stats() {
  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Group style={{ flexWrap: "nowrap" }}>
          <RingProgress
            size={60}
            roundCaps
            thickness={6}
            sections={[{ value: stat.progress, color: stat.color }]}
            label={
              <Center>
                <Icon size="1.4rem" stroke={1.5} />
              </Center>
            }
          />

          <div>
            <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {stat.stats}
            </Text>
          </div>
        </Group>
      </Paper>
    );
  });

  return (
    <SimpleGrid
      cols={3}
      style={{ width: "100%", maxHeight: "100%" }}
      breakpoints={[{ maxWidth: "1060", cols: 1 }]}
    >
      {stats}
    </SimpleGrid>
  );
}
