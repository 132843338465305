// index.js
import React from "react";
import { Container, Title, Paper, Divider } from "@mantine/core";
import DesignComponent from "./UI/DesignComponent";

const DesignCard = () => {
  return (
    <Container size="sm">
      <Paper shadow="sm" padding="lg" style={{ marginBottom: "20px" }}>
        <Title order={1}>Choose Your Business Card Design</Title>
        <Divider margin={{ top: "xs", bottom: "sm" }} />

        <p style={{ marginBottom: "20px" }}>
          Select the perfect design for your business card from the options
          below.
        </p>

        <DesignComponent />
      </Paper>
    </Container>
  );
};

export default DesignCard;
